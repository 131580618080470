import {useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";
import {useInView} from "react-intersection-observer";
import {LeaderboardUpdatedProps} from "../../types/ComponentProps";
import WithNavTemplate from "../../components/template/WithNavTemplate";
import useGetLeaderBoard from "../../hooks/useGetLeaderBoard";
import {useNotification} from "../../context/NotificationContext";
import Navigation from "../../components/navigation/Navigation";
import Spinner from "../../components/spinner/Spinner";

const LeaderboardPage = () => {
  const {ref, inView} = useInView();
  const {
    socketState,
    setNotificationModal,
    setSelectedPostNotification,
    setSelectedNotificationId,
  } = useNotification();

  const navigate = useNavigate();
  const navigationOnClick = () => {
    navigate("/");
  };
  const {
    leaderBoardList,
    isLoadingLeaderBoardList,
    isFetchingNextPageLeaderBoardList,
    hasNextPageLeaderBoardList,
    fetchNextPageLeaderBoardList,
  } = useGetLeaderBoard();

  const leaderboardUsers =
    leaderBoardList?.pages.flatMap(
      (user: LeaderboardUpdatedProps) => user.user
    ) || [];

  useEffect(() => {
    if (inView && hasNextPageLeaderBoardList) {
      fetchNextPageLeaderBoardList();
    }
  }, [inView, fetchNextPageLeaderBoardList, hasNextPageLeaderBoardList]);

  if (!socketState) {
    return (
      <>
        <Navigation />
        <div className="inside-page-container-blk">
          <div
            className="main-container-blk"
            style={{
              position: "relative",
              height: "100vh",
              paddingTop: "200px",
            }}>
            <Spinner alignSpin="center-spinner" variant="large-spinner" />
          </div>
        </div>
      </>
    );
  }

  return (
    <WithNavTemplate
      socket={!socketState ? null : socketState}
      setNotificationModal={setNotificationModal}
      setSelectedPostNotification={setSelectedPostNotification}
      setSelectedNotificationId={setSelectedNotificationId}>
      <div className="inside-page-container-blk">
        <div className="leaderboard-main-section">
          <div className="page-title-blk">
            <i
              onClick={navigationOnClick}
              className="fa-solid fa-arrow-left arrow-back-page"></i>
            <h1>Leaderboard</h1>
          </div>
          <div className="leader-board-list-page-container">
            <div className="leader-board-list-header">
              <div>
                <h2>Rank</h2>
              </div>
              <div>
                <h2>Name</h2>
              </div>
              <div>
                <h2>Points</h2>
              </div>
            </div>
            {isLoadingLeaderBoardList ? (
              <div style={{margin: "20px"}}>
                <Spinner variant="medium-spinner" alignSpin="center-spinner" />
              </div>
            ) : (
              leaderboardUsers?.map((data, index: number) => {
                return (
                  <div key={index} className="leader-board-list-blk-section">
                    <div>
                      <p
                        className={`${
                          index + 1 === 1
                            ? "leader-board-list-blk-section-rank-bubble-one"
                            : index + 1 === 2
                            ? "leader-board-list-blk-section-rank-bubble-two"
                            : index + 1 === 3
                            ? "leader-board-list-blk-section-rank-bubble-three"
                            : ""
                        }`}>
                        <span>{index + 1}</span>
                      </p>
                    </div>
                    <div className="flex-box-blk">
                      <div>
                        <Link to={`/profile/${data?.user?.id}`}>
                          <img alt="profile" src={data?.profileImageURL} />
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={`/profile/${data?.user?.id}`}
                          className="leaderboard-name-blk-link">
                          <h2>{data?.user?.preferredName}</h2>
                          <h3 className="leader-board-list-blk-section-email-section">
                            {data?.user?.email}
                          </h3>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <h2>{data?.points}</h2>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div ref={ref} style={{height: "1px"}}></div>
          {isFetchingNextPageLeaderBoardList && (
            <div
              style={{
                display: "block",
                padding: "20px 0px",
              }}>
              <Spinner variant="medium-spinner" alignSpin="center-spinner" />
            </div>
          )}
        </div>
      </div>
    </WithNavTemplate>
  );
};

export default LeaderboardPage;
