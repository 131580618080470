import {useState} from "react";
import ProfileTabStyles from "./ProfileTabStyles.module.css";
import SpaceTabPublic from "./SpaceTabPublic";
import {UserTabDetailsProps} from "../../types/ComponentProps";
import MentionedTabPublic from "./MentionTabPublic";
import LikeTabPublic from "./LikeTabPublic";

const ProfileTabPublic = ({userDetails}: UserTabDetailsProps) => {
  const [activeTab, setActiveTab] = useState("spaceTab");

  const renderContent = () => {
    switch (activeTab) {
      case "spaceTab":
        return <SpaceTabPublic userDetails={userDetails} />;
      case "mentionTab":
        return <MentionedTabPublic userDetails={userDetails} />;
      case "likesTab":
        return <LikeTabPublic userDetails={userDetails} />;
      default:
        return null;
    }
  };

  return (
    <div className={ProfileTabStyles.profileMainContainer}>
      <div className={ProfileTabStyles.profileTabContainer}>
        <div
          className={`${ProfileTabStyles.tabProfile} ${
            activeTab === "spaceTab" ? `${ProfileTabStyles.activeTab}` : ""
          }`}
          onClick={() => setActiveTab("spaceTab")}>
          Space
        </div>
        <div
          className={`${ProfileTabStyles.tabProfile} ${
            activeTab === "mentionTab" ? `${ProfileTabStyles.activeTab}` : ""
          }`}
          onClick={() => setActiveTab("mentionTab")}>
          Mentioned
        </div>
        <div
          className={`${ProfileTabStyles.tabProfile} ${
            activeTab === "likesTab" ? `${ProfileTabStyles.activeTab}` : ""
          }`}
          onClick={() => setActiveTab("likesTab")}>
          Likes
        </div>
      </div>
      <hr className={ProfileTabStyles.hrSectionBlk} />
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default ProfileTabPublic;
