import {getDaysLeftInMonth} from "../../utils/getDaysLeft";
import SummaryStyles from "./SummaryStyles.module.css";

const Allowance = () => {
  const daysLeft = getDaysLeftInMonth();
  return (
    <div className={SummaryStyles.allowanceBlk}>
      <i className="fa-regular fa-calendar"></i>
      <p>
        Giveable points will reset in <span>{daysLeft} days</span>
      </p>
    </div>
  );
};

export default Allowance;
