import React, {useEffect, useState} from "react";
import AdminPostCard from "../postCard/AdminPostCard";
import {jwtDecode} from "jwt-decode";
import {useInView} from "react-intersection-observer";
import useGetAllPostsById from "../../hooks/useGetAllPostsById";
import Spinner from "../spinner/Spinner";
import PostCard from "../postCard/PostCard";
import EditPostModal from "../editPostModal/EditPostModal";
import LikesModal from "../likesModal/LikesModal";
import {PostCardDetailsProps, TokenPayload} from "../../types/ComponentProps";

const SpaceTab = () => {
  const {ref, inView} = useInView();
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );
  const [editPostModal, setEditPostModal] = useState<boolean>(false);
  const [selectedCardModal, setSelectedCardModal] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [editCardSelection, setEditCardSelection] = useState<number | null>(
    null
  );
  const {
    isLoading,
    getAllPostsByIdData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetAllPostsById(Number(userDetails?.id));

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return (
    <>
      {editPostModal ? (
        <EditPostModal
          setEditPostModal={setEditPostModal}
          editCardSelection={editCardSelection}
          setEditCardSelection={setEditCardSelection}
        />
      ) : null}

      {selectedCardModal ? (
        <LikesModal
          post={selectedCard}
          setSelectedCardModal={setSelectedCardModal}
        />
      ) : null}

      {isLoading ? (
        <Spinner alignSpin="center-spinner" variant="medium-spinner" />
      ) : getAllPostsByIdData?.pages[0]?.posts.length === 0 ? (
        <p className="not-available-blk">No posts available</p>
      ) : (
        getAllPostsByIdData?.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.posts.map((post: PostCardDetailsProps, index: number) =>
              post?.badgeId ? (
                <AdminPostCard
                  key={index}
                  post={post}
                  setSelectedCardModal={setSelectedCardModal}
                  setSelectedCard={setSelectedCard}
                />
              ) : (
                <PostCard
                  key={index}
                  post={post}
                  setSelectedCardModal={setSelectedCardModal}
                  setSelectedCard={setSelectedCard}
                  setEditPostModal={setEditPostModal}
                  setEditCardSelection={setEditCardSelection}
                />
              )
            )}
          </React.Fragment>
        ))
      )}
      <div ref={ref}>
        {isFetchingNextPage ? (
          <Spinner alignSpin="center-spinner" variant="medium-spinner" />
        ) : hasNextPage ? null : !isLoading &&
          getAllPostsByIdData?.pages?.[0]?.posts?.length !== 0 ? (
          <div className="all-posts-label-blk">
            <div></div>
            <p>You have seen all the posts</p>
            <div></div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SpaceTab;
