import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import updateComment from "../../api/updateComment";
import {CommentPostCardProps} from "../../types/ComponentProps";
import CommentAvatarList from "./CommentAvatarList";
import dateFormatter from "../../utils/dateFormat";
import CommentsModalStyles from "./CommentsModalStyles.module.css";
import {getLevel} from "../../utils/getLevel";
import CommentListUpdate from "./CommentListUpdate";
import Spinner from "../spinner/Spinner";
import {Link} from "react-router-dom";

const CommentPostCard = ({data}: CommentPostCardProps) => {
  const queryClient = useQueryClient();
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [editedMessage, setEditedMessage] = useState({
    message: data?.message || "",
  });

  const levelInfo = getLevel(data?.userPoints ?? 0);
  const levelIndex = levelInfo?.index != null ? levelInfo.index + 1 : null;

  const onEditClickHandler = () => {
    setIsOnEdit(true);
  };

  const onMessageChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target;
    setEditedMessage((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const updateCommentMutation = useMutation({
    mutationFn: () =>
      updateComment(Number(data?.commentId ?? 0), editedMessage.message),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPostComments"],
      });
      setIsLoadingEdit(false);
    },
    onError: (error: Error) => {
      console.error("Error liking post:", error.message);
    },
  });

  const handleSave = async () => {
    setIsLoadingEdit(true);
    await updateCommentMutation.mutateAsync();
    setIsOnEdit(false);
  };

  const handleCancel = () => {
    setEditedMessage({message: data?.message || ""});
    setIsOnEdit(false);
  };

  return (
    <div className={CommentsModalStyles.commentListContainerBlk}>
      <div className={CommentsModalStyles.containerCommentListBlk}>
        <div>
          <>
            {data?.userId === 1 || data?.userId === 2 ? (
              <CommentAvatarList userId={data?.userId ?? 0} />
            ) : (
              <Link to={`/profile/${data?.userId}`}>
                <CommentAvatarList userId={data?.userId ?? 0} />
              </Link>
            )}
          </>
        </div>
        <div>
          <div className={CommentsModalStyles.flexBoxBlkCommentList}>
            <div>
              <div className={CommentsModalStyles.flexBoxSectionName}>
                <>
                  {data?.userId === 1 || data?.userId === 2 ? (
                    <h3>{data?.preferredName}</h3>
                  ) : (
                    <Link
                      to={`/profile/${data?.userId}`}
                      className={CommentsModalStyles.nameHoverCardBlk}>
                      <h3>{data?.preferredName}</h3>
                    </Link>
                  )}
                </>
                <span>{data?.pronouns}</span>
              </div>
              {data?.isAdmin ? (
                <h4 className={CommentsModalStyles.levelSpanSection}>
                  Administrator
                </h4>
              ) : (
                <h4 className={CommentsModalStyles.levelSpanSection}>
                  Lv. {levelIndex} - {levelInfo?.level}
                </h4>
              )}
            </div>
            <div>
              <p>{dateFormatter(data?.createdAt as string)}</p>
            </div>
            <CommentListUpdate
              post={data}
              onEditClickHandler={onEditClickHandler}
            />
          </div>
          {isOnEdit ? (
            <div className={CommentsModalStyles.onEditSectionBlk}>
              <textarea
                className={CommentsModalStyles.editableMessageSection}
                name="message"
                value={editedMessage.message}
                onChange={onMessageChangeHandler}
                maxLength={1024}
              />
              <div className={CommentsModalStyles.onEditFooterSectionBlk}>
                <button onClick={handleCancel}>Cancel</button>
                <button onClick={handleSave}>
                  {isLoadingEdit ? (
                    <Spinner
                      variant="extra-small-spinner"
                      alignSpin="center-spinner"
                    />
                  ) : (
                    <span className="fa-solid fa-arrow-right"></span>
                  )}
                </button>
              </div>
            </div>
          ) : (
            <p>{data?.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentPostCard;
