import {useEffect} from "react";
import PublicPersonalDetails from "../myProfile/PublicPersonalDetails";
import ProfileTabPublic from "../profileTab/ProfileTabPublic";
import ProfilePublicStyles from "./ProfilePublicStyles.module.css";
import {useNavigate} from "react-router-dom";

type UserDetail = {
  id: number;
  jobTitle: string;
  preferredName: string;
};

type ProfilePublicProps = {
  isLoading?: boolean;
  user: {
    success: boolean;
    user: UserDetail;
  };
};

const ProfilePublic = ({user}: ProfilePublicProps) => {
  const navigate = useNavigate();

  const userDetails = user?.user;
  useEffect(() => {
    if (user?.user?.id === 1 || user?.user?.id === 2) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div className={ProfilePublicStyles.myProfileContainerSection}>
      <div>
        <PublicPersonalDetails userDetails={userDetails} />
      </div>
      <div>
        <ProfileTabPublic userDetails={userDetails} />
      </div>
    </div>
  );
};

export default ProfilePublic;
