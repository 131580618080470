import {useState} from "react";
import {IxieChatboxProps} from "../../types/ComponentProps";

const IxieChatbox = ({userDetails}: IxieChatboxProps) => {
  const [isIframeVisible, setIsIframeVisible] = useState(false);

  const toggleIframeVisibility = () => {
    setIsIframeVisible((prev) => !prev);
  };

  return (
    <div className="ixieChatbot-container">
      <div
        role="button"
        onClick={toggleIframeVisibility}
        aria-label="Show/Hide iXie"
        tabIndex={0}>
        {isIframeVisible ? (
          <i className="fa-solid fa-xmark"></i>
        ) : (
          <i className="fa-solid fa-message"></i>
        )}
      </div>
      {isIframeVisible && (
        <iframe
          id="ixie"
          title="iXie"
          src={`https://d1i4rmb4irdnyz.cloudfront.net/?embed=true&embed_options=hide_loading_screen&email=${userDetails?.email}`}></iframe>
      )}
    </div>
  );
};

export default IxieChatbox;
