import {useEffect, useState} from "react";
import NotificationList from "./NotificationList";
import {
  NavigationProps,
  NotificationSenderProps,
} from "../../types/ComponentProps";
import NotificationStyles from "./Notification.module.css";
import useGetAllNotification from "../../hooks/useGetAllNotification";

const Notification = ({
  socket,
  notificationToggle,
  setNotificationToogle,
  setNotificationModal,
  setSelectedPostNotification,
  setSelectedNotificationId,
}: NavigationProps) => {
  const {getNotificationByIdData, refetch, isLoading} = useGetAllNotification();
  const [notification, setNotifications] = useState<NotificationSenderProps>(
    []
  );
  const [localNotificationOn, setLocalNotificationOn] = useState<boolean>(
    localStorage.getItem("notificationOff") === "true"
  );

  const onClickHandler = () => {
    setNotificationToogle?.(!notificationToggle);
    localStorage.setItem("notificationOff", "true");
    setLocalNotificationOn(true);
  };

  useEffect(() => {
    if (notification.length > 0) {
      refetch();
    }
  }, [notification, refetch]);

  useEffect(() => {
    if (socket) {
      const handleNewNotification = (data: NotificationSenderProps) => {
        setNotifications((previous: any) => [...previous, data]);
        localStorage.removeItem("notificationOff");
        setLocalNotificationOn(false);
      };

      socket.on("getNotification", handleNewNotification);
      socket.on("getNotificationComment", handleNewNotification);
      socket.on("getNotificationMention", handleNewNotification);
      socket.on("getNotificationBadgeMention", handleNewNotification);

      return () => {
        socket.off("getNotification", handleNewNotification);
        socket.off("getNotificationComment", handleNewNotification);
        socket.off("getNotificationMention", handleNewNotification);
        socket.off("getNotificationBadgeMention", handleNewNotification);
      };
    }
  }, [socket]);

  return (
    <div className={NotificationStyles.notificationMainContainer}>
      {!isLoading &&
        getNotificationByIdData?.notifications.length > 0 &&
        !localNotificationOn && (
          <div className={NotificationStyles.notificationSpanCounter}>
            <span>
              {getNotificationByIdData?.notifications.length === 99
                ? `${getNotificationByIdData?.notifications.length}+`
                : getNotificationByIdData?.notifications.length}
            </span>
          </div>
        )}
      {notificationToggle && (
        <NotificationList
          setToggle={setNotificationToogle}
          setNotificationModal={setNotificationModal}
          setSelectedPostNotification={setSelectedPostNotification}
          setSelectedNotificationId={setSelectedNotificationId}
        />
      )}
      <div className={NotificationStyles.notificationContainer}>
        <i className="fa-solid fa-bell" onClick={onClickHandler}></i>
      </div>
    </div>
  );
};

export default Notification;
