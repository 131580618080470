import Spinner from "../spinner/Spinner";
import {LikeButtonProps} from "../../types/ComponentProps";
import LikeButtonStyle from "./LikeButtonStyles.module.css";
import {useEffect} from "react";

const LikeButton = ({
  isLiked,
  onLikeButtonClickHandler,
  likeIsLoading,
}: LikeButtonProps) => {
  return (
    <button
      className={`${LikeButtonStyle.likeButton} ${
        isLiked ? LikeButtonStyle.isLiked : null
      }`}
      onClick={onLikeButtonClickHandler}>
      {likeIsLoading ? (
        <Spinner variant="extra-small-spinner" alignSpin="center-spinner" />
      ) : (
        <>
          <i className="fa-solid fa-thumbs-up"></i>
          <span>Like{isLiked ? "d" : null}</span>
        </>
      )}
    </button>
  );
};

export default LikeButton;
