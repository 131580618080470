import {useState, useEffect} from "react";
import {jwtDecode} from "jwt-decode";
import useGetTeamList from "../../hooks/useGetTeamList";
import TeamBlockAvatar from "../teamBlock/TeamBlockAvatar";
import DefaultImage from "../../assets/img/default-profile-photo.png";
import {randomList} from "../../utils/randomList";
import {
  TeamBlockProps,
  TokenPayload,
  CreateUserProps,
} from "../../types/ComponentProps";
import TeamProfileDetails from "../../components/teamBlock/TeamProfileDetails";
import TeamBlockStyles from "./TeamBlockStyles.module.css";
import {Link} from "react-router-dom";

const TeamBlock = () => {
  const {getTeamListData, isLoading} = useGetTeamList();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [teamList, setTeamList] = useState<TeamBlockProps[]>([]);
  let hoverTimer: NodeJS.Timeout;

  useEffect(() => {
    if (getTeamListData?.users) {
      const userDetails = jwtDecode<TokenPayload>(
        JSON.stringify(localStorage.getItem("accessTokenGC"))
      );

      const filteredUserData = getTeamListData.users.filter(
        (user: CreateUserProps) => user?.id !== userDetails?.id
      );

      const randomizedList = randomList<TeamBlockProps>(filteredUserData);
      setTeamList(randomizedList.slice(0, 3));
    }
  }, [getTeamListData]);

  const onMouseOverHandler = (id: number) => {
    clearTimeout(hoverTimer);
    setSelectedUserId(id);
    setShowDetails(true);
  };

  const onMouseOutHandler = () => {
    hoverTimer = setTimeout(() => {
      setShowDetails(false);
      setSelectedUserId(null);
    }, 0);
  };

  const onProfileDetailsMouseEnter = () => {
    clearTimeout(hoverTimer);
  };

  const onProfileDetailsMouseLeave = () => {
    setShowDetails(false);
    setSelectedUserId(null);
  };

  return (
    <>
      {selectedUserId && showDetails && (
        <TeamProfileDetails
          userId={selectedUserId}
          showDetails={showDetails}
          onMouseEnter={onProfileDetailsMouseEnter}
          onMouseLeave={onProfileDetailsMouseLeave}
        />
      )}

      <div className={TeamBlockStyles.teamBlockSection}>
        {teamList.map((data: TeamBlockProps, index: number) => (
          <Link
            to={`/profile/${data?.id}`}
            style={{textDecoration: "none"}}
            key={index}>
            <div
              className={TeamBlockStyles.teamListBlockSection}
              onMouseEnter={() => onMouseOverHandler(data?.id)}
              onMouseLeave={onMouseOutHandler}>
              <div className={TeamBlockStyles.headerSectionStyles}>
                {isLoading ? (
                  <img alt="profile" src={DefaultImage} />
                ) : !data?.profileImage ? (
                  <img alt="profile" src={DefaultImage} />
                ) : (
                  <TeamBlockAvatar userId={data?.id} />
                )}
              </div>
              <div className={TeamBlockStyles.preferredNameSection}>
                <div className={TeamBlockStyles.flexBoxStyleBlock}>
                  <h2>{data?.preferredName}</h2>
                  <span className={TeamBlockStyles.pronounSection}>
                    {data?.user_profile?.pronouns}
                  </span>
                </div>
                <h3>{data?.email}</h3>
              </div>
              <div>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default TeamBlock;
