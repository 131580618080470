import {useState} from "react";
import {jwtDecode} from "jwt-decode";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import useGetProfilePicture from "../../hooks/useGetProfilePicture";
import {
  CommentPostFooterProps,
  CommentPostProps,
  TokenPayload,
} from "../../types/ComponentProps";
import Spinner from "../spinner/Spinner";
import addComment from "../../api/addComment";
import CommentsModalStyles from "./CommentsModalStyles.module.css";

const CommentPost = ({post, socket}: CommentPostFooterProps) => {
  const userDetails = jwtDecode<TokenPayload>(
    JSON.stringify(localStorage.getItem("accessTokenGC"))
  );

  const {getProfileImage} = useGetProfilePicture(Number(userDetails?.id));

  const queryClient = useQueryClient();
  const initialState: CommentPostProps = {
    message: "",
  };

  const [postComment, setPostComment] = useState(initialState);
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);

  const onClickHandlerCommentIO = () => {
    socket?.emit("sendCommentNotification", {
      senderDetails: userDetails,
      receiverDetails: post,
    });
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const {value, name} = e.target;
    if (message) {
      setMessage(false);
    }
    setPostComment((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const addComentMutation = useMutation({
    mutationFn: () => addComment(postComment, post?.postId ?? 0),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ["getPostComments"]});
      setPostLoading(false);
    },
    onError: (error: Error) => {
      console.error("Error liking post:", error.message);
      setPostLoading(false);
    },
  });

  const onResetHandler = () => {
    setPostComment(initialState);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!postComment.message) {
      return setMessage(true);
    }
    setPostLoading(true);
    try {
      await addComentMutation.mutateAsync();
      onClickHandlerCommentIO();
      setPostComment({message: ""});
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  return (
    <div className={CommentsModalStyles.postCommentBlkSection}>
      <div className={CommentsModalStyles.avatarCommentSection}>
        <img alt="profile" src={getProfileImage?.profileImage} />
      </div>
      <div
        className={`${CommentsModalStyles.commentPostSectionBlk} ${
          message ? CommentsModalStyles.emptyOnSubmit : null
        }`}>
        <form onSubmit={onSubmit}>
          <div>
            <textarea
              onChange={onChangeHandler}
              placeholder="Write a comment.."
              value={postComment.message}
              name="message"
              spellCheck="false"
              maxLength={1024}></textarea>
            <div className={CommentsModalStyles.commentPostFooter}>
              <div className="flex-box-blk">
                <button
                  className={CommentsModalStyles.cancelButton}
                  type="reset"
                  onClick={onResetHandler}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={CommentsModalStyles.buttonSubmit}>
                  {postLoading ? (
                    <Spinner
                      variant="small-spinner"
                      alignSpin="center-spinner"
                    />
                  ) : (
                    <span className="fa-solid fa-arrow-right"></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentPost;
