import Spinner from "../spinner/Spinner";
import {
  NotificationListProps,
  NotificationToggleProps,
} from "../../types/ComponentProps";
import useGetAllNotification from "../../hooks/useGetAllNotification";
import NotificationStyles from "./Notification.module.css";
import {useNotification} from "../../context/NotificationContext";

const NotificationList = ({setToggle}: NotificationToggleProps) => {
  const {
    setNotificationModal,
    setSelectedNotificationId,
    setSelectedPostNotification,
  } = useNotification();
  const {getNotificationByIdData, isLoading} = useGetAllNotification();

  const toggleNotification = () => {
    if (setToggle) {
      setToggle(false);
    }
  };

  const showModalNotificationClickHandler = (
    postNumber: number,
    notificationId: number
  ) => {
    if (
      setSelectedPostNotification &&
      setNotificationModal &&
      setSelectedNotificationId
    ) {
      setNotificationModal(true);
      setSelectedNotificationId(Number(notificationId));
      setSelectedPostNotification(Number(postNumber));
    }
  };

  return (
    <div
      onMouseLeave={toggleNotification}
      className={NotificationStyles.notificationListContainer}>
      <h1 className={NotificationStyles.headerNotificationText}>
        Notifications
      </h1>
      <div
        className={NotificationStyles.notificationListScrollBlock}
        style={
          getNotificationByIdData?.notifications.length > 5
            ? {paddingRight: "10px"}
            : {overflowY: "hidden"}
        }>
        {isLoading ? (
          <div style={{display: "block", padding: "0px 0px"}}>
            <Spinner variant="medium-spinner" alignSpin="center-spinner" />
          </div>
        ) : getNotificationByIdData?.notifications.length === 0 ? (
          <div className={NotificationStyles.noNotificationContainer}>
            <p>
              You have no notifications right now.
              <br /> Come back later
            </p>
          </div>
        ) : (
          getNotificationByIdData?.notifications.map(
            (data: NotificationListProps, index: number) => {
              return (
                <div
                  className={NotificationStyles.listContent}
                  key={index}
                  onClick={() => {
                    showModalNotificationClickHandler(
                      data?.postId ?? 0,
                      data?.notificationId ?? 0
                    );
                  }}>
                  <div>
                    <p>{data?.content}</p>
                  </div>
                </div>
              );
            }
          )
        )}
      </div>
    </div>
  );
};

export default NotificationList;
